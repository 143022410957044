import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import "./assets/scss/main.scss";
import App from './App';

<link rel="icon" href="%PUBLIC_URL%/favicon.ico" />


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );