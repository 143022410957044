import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const TermsOfServicePage = () => {
  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Terms of Service</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <div className="terms-container">
                <h2>Terms of Service for Likely Spurious</h2>
                  {/* <!--Spacer--> */}
                <p>Last Updated: January 8th, 2024</p>

                {/* Add sections here */}
                <h3>1. Acceptance of Terms</h3>
                <p>By accessing or using our Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Service.</p>

                <h3>2. Description of Service</h3>
                <p>Our Service allows users to upload data for analysis and provides information on what time series data in the LikelySpurious databases may have a relationship with users data, though we do not provide the series data within the LikelySpurious databases to users. We strive to offer high-quality data analysis, but you agree that the use of the Service and any data provided is at your sole risk.</p>

                <h3>3. User Responsibilities</h3>
                <p>You must ensure that all data uploaded to the Service complies with applicable laws and does not infringe on any third party's rights.<br /> 
                You are responsible for maintaining the confidentiality of your account and password.</p>

                <h3>4. Intellectual Property Rights</h3>
                <p>The Service, including its interface, algorithms, and information provided, is the property of Likely Spurious LLC and is protected by intellectual property laws.<br />
                You are granted a non-exclusive, non-transferable, revocable license to use the Service for your personal or business use.</p>

                <h3>5. Data Usage</h3>
                <p>You retain all rights to the data you upload. However, you grant us a license to use this data for the purpose of providing and improving our Service.<br />
                We may use aggregated and anonymized data for our business purposes, including but not limited to, improving our algorithms and service offerings.</p>

                <h3>6. Disclaimer of Warranties</h3>
                <p>Our Service is provided "as is" and "as available" without any warranties, either expressed or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.<br />
                We do not warrant that the Service will be uninterrupted, timely, secure, or error-free.<br />
                We do not make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy, reliability, or content of any information provided through the Service.</p>

                <h3>7. Limitation of Liability</h3>
                <p>In no event will Likely Spurious LLC, its affiliates, or its suppliers be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if advised of the possibility of such damages), resulting from the use or the inability to use the Service.<br />
                Our total liability to you for all claims arising from or relating to the Service will not exceed the amount you paid us (if any) for the Service in the twelve (12) months preceding the claim.</p>

                <h3>8. Indemnification</h3>
                <p>You agree to indemnify and hold harmless Likely Spurious LLC, its officers, directors, employees, suppliers, and affiliates, from any claims, damages, losses, liabilities, and expenses (including attorneys' fees) arising from your use of the Service or violation of these Terms.</p>

                <h3>9. Modifications to Terms</h3>
                <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after such changes constitutes your acceptance of the new Terms.</p>

                <h3>10. Governing Law</h3>
                <p>These Terms shall be governed by the laws of New Jersey, without regard to its conflict of law provisions.</p>

                <h3>11. Copyright</h3>
                <p>If a user suspects that the Service has been used in a way that infringes on copyright, they must inform LikelySpurious LLC by sending a notice to likelyspurious@gmail.com. This notice must include all information required by the Digital Millennium Copyright Act (DMCA).</p>

                <h3>12. Contact Information</h3>
                <p>If you have any questions about these Terms, please <Link className="normal-link" to="/contact">contact us</Link>.</p>
            </div>  
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default TermsOfServicePage;
