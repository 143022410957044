import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import axios from 'axios';

import AuthContext from '../context/AuthContext'

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const PasswordResetReqPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Reset message
      setMessage('');
  
      axios.post('https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/users/reset_password/', { email })
        .then(response => {
          // Handle success response
          // You might want to update this message based on the actual response from your backend
          setMessage('If an account with that email exists, we have sent a password reset link.');
        })
        .catch(error => {
          // Handle error response
          console.error('Password reset error:', error);
          setMessage('There was an error processing your request. Please try again.');
        });
    };
  
    return (
    <div className="page-container">
        <div className="ptf-site-wrapper animsition  ptf-is--about-us">
            <Helmet>
            <title>LikelySpurious - Password Reset Request</title>
            </Helmet>
            {/* End Page SEO Content */}
            <div className="ptf-site-wrapper__inner">
            <HeaderDefault />
            {/* End  HeaderHomeDefault */}
            <h2 className="small-heading">Reset Your Password</h2>
                {/* <!--Spacer--> */}
                <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.5rem" }}
                ></div>
                <div>
                <form className='login-form' onSubmit={handleSubmit}>
                    <label htmlFor="email">Email Address:</label>
                    <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    />
                    <button className='form-button' type="submit">Send Reset Link</button>
                </form>
                {message && <p>{message}</p>}
            </div>
            </div>
            </div>
        <Footer />
    </div>
  );
};

export default PasswordResetReqPage;