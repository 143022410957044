import React, { useState } from 'react';
import axios from 'axios';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    console.log("Data submitting!")
    e.preventDefault();

    try {
      // Update the URL to match your Django endpoint for sending emails
      const response = await axios.post('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/send-email/', {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      });
      console.log('Response from server:', response.data.success);
      setFormData({ name: '', email: '', message: '' }); // Reset form data
      if (response.data.success == "Email sent successfully") {
        setSuccessMessage(response.data.success)
      }
      // You can handle the response data as needed, e.g., show a success message to the user.
    } catch (error) {
      console.error('Error:', error);
      // Handle errors, e.g., display an error message to the user.
    }
  };

  return (
    <div>
      {successMessage && <div className="email-success">Message sent successfully.</div>}
      <form onSubmit={handleSubmit} className="contact_form">
        <div className="ptf-form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="ptf-form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="ptf-form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="ptf-submit-button">Submit</button>
      </form>
    </div>
  );
}

export default ContactForm;
