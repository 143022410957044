import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import "../App.css";

function SourcesModule() {
  const { user, authTokens, updateToken } = useContext(AuthContext); // Use authTokens and updateToken from context
  const [isOpen, setIsOpen] = useState(false); // Start with the section closed
  const toggleOpen = () => setIsOpen(!isOpen);
  const [sourceData, setSourceData] = useState([]);
  const [retryAttempted, setRetryAttempted] = useState(false);

  const fetchSourceData = async () => {
    // Here, the direct use of 'authTokens' from context instead of localStorage
    const requestOptions = {
      method: 'GET',
      headers: {
        //'Authorization': `JWT ${authTokens.access}`,
        'Content-Type': 'application/json',
      }
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/data-sources/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken();
          setRetryAttempted(true);
          if (refreshSuccess) {
            fetchSourceData();
          }
          setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      }
      const source_data = await response.json();
      if(source_data.source_data) {
        setSourceData(source_data.source_data);
      }
      return source_data.source_data;
    } catch (error) {
      console.error('There has been a problem fetching source data:', error);
      return [];
    } 
  };

  useEffect(() => {
    fetchSourceData();
  }, []);

  return (
    <div>
      <div onClick={toggleOpen} className="header">
        <h2 className='header-padding'>Sources / Citations</h2>
        {/* Update the icon's class based on the isOpen state */}
        <i className={`lnil ls-chevron ${isOpen ? 'lnil-chevron-down' : 'lnil-chevron-right'}`}></i>
      </div>
      {isOpen && (
        <div>
        <div className='paragraph-padding'>Our core data sources consist of FRED and GDELT. Therefore, the original sources may consist of the entities listed below. Original source information for a specific series can be found by accessing the Source URL in the above tables. Certain data is available to plot based on the sources' terms of use. Assume series are copyrighted and review each sources terms before usage.</div>       
        <div className='paragraph-padding'>Annotations text generated by ChatGPT, a language model developed by OpenAI.</div>     
         <div className='table-container'><table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Via</th>
            </tr>
          </thead>
          <tbody>
            {sourceData.map((item, index) => (
              <tr key={index}>
                <td className="series-name">{item.name || '-'}</td>
                <td>{item.via || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table></div>
        </div>
      )}
    </div>
  );
}

export default SourcesModule;
