import React from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';


import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const PrivacyPolicyPage = () => {
  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Privacy Policy</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <div className="privacy-container">
                <h2>Privacy Policy for Likely Spurious</h2>
                  {/* <!--Spacer--> */}
                    <p>Effective Date: January 8th, 2024</p>

                    <h3>1. Information Collection</h3>
                    <p>User-Provided Information: We collect information that you provide directly to us, such as when you create an account, upload data, or communicate with us.<br />
                    Automatically Collected Information: We automatically collect certain information when you use our Service, including usage details, IP addresses, and information collected through cookies and other tracking technologies.</p>

                    <h3>2. Use of Information</h3>
                    <p>The information we collect is used to provide, maintain, and improve our Service, to communicate with you, and to personalize your experience.<br />
                    We may use the information for research and analytics purposes, to monitor and analyze usage and trends, and to develop new features and services.</p>

                    <h3>3. Data Sharing and Disclosure</h3>
                    <p>With Consent: We will share personal information with companies, organizations, or individuals outside of Likely Spurious LLC when we have your consent to do so.<br />
                    For External Processing: We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures.
                    For Legal Reasons: We will share personal information with companies, organizations, or individuals outside of Likely Spurious LLC if we believe that access, use, preservation, or disclosure of the information is reasonably necessary to meet any applicable law, regulation, legal process, or enforceable governmental request.</p>

                    <h3>4. Data Security</h3>
                    <p>We implement appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure, or destruction of data. However, no method of transmission over the Internet or method of electronic storage is 100% secure.</p>

                    <h3>5. International Data Transfers</h3>
                    <p>Information that we collect may be stored and processed in any country in which we or our affiliates or service providers maintain facilities. By using our Service, you consent to the transfer of information to countries outside of your country of residence, which may have different data protection rules than in your country.</p>

                    <h3>6. Your Data Rights</h3>
                    <p>You may have certain rights regarding your personal data, including the right to access, correct, or delete the information we hold about you. You can typically exercise these rights through your account settings or by <Link className="normal-link" to="/contact">contacting us</Link> directly.</p>

                    <h3>7. Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top of this policy.</p>

                    <h3>8. Contact Us</h3>
                    <p>If you have any questions about this Privacy Policy, please <Link className="normal-link" to="/contact">contact us</Link>.</p>
                </div>
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default PrivacyPolicyPage;
