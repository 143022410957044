import React from "react";
import { Helmet } from "react-helmet";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const approachContent = [
    {
        title: "Upload",
        descriptions: `Upload your CSV containing time series data, with the first column as a date column and the second as your time series of interest. Note the names of the columns are ignored.`,
        delayAnimation: "0",
    },
    {
        title: "Validate",
        descriptions: `We validate that your data meets some basic requirements such as: date column can be converted to dates our application recognizes (we can work with various formats, but if validation fails, we recommend converting your dates to the “YYYY-MM-DD” format), dates are at consistent equal intervals, your second column can be recognized as numerical values by our application, there is no missing data, there are a minimum number of data points, and your series is in one of our accepted frequencies. Accepted Frequencies: daily, weekly, monthly, quarterly, and annual.`,
        delayAnimation: "100",
    },
    {
        title: "Initial Analysis",
        descriptions: `The application compares your time series against our database of over 1 million series. Your series will only be compared with those that have sufficient overlap after resampling. For instance, if you upload a monthly time series spanning 2010-2023, it may be resampled to annual data for comparison against our annual series with values from 2010 to 2013. However, the 13 overlapping data points may not meet the minimum required for analysis. As another example, if you upload annual data from 2005 to 2023, your series will be compared against several of our annual series covering most of that period. It may not be compared to series spanning from 2005-2016, as the 12 overlapping data points might not fulfill the minimum required for analysis.`,
        delayAnimation: "200",
    },
    {
        title: "Post Analysis",
        descriptions: `The initial scan generates a list of candidate series. These candidates undergo further analysis using causal modeling techniques to determine if the relationship is spurious due to common confounders (e.g. the observed relationship between two series is actually influenced by factors such as population growth or inflation).`,
        delayAnimation: "300",
    },
    {
      title: "User Interface",
      descriptions: `The top candidate series, along with any noted confounders, will be available for review in the analyses section of the UI. This includes basic time-series and scatter plot modules, as well as links to the series sources. Generally, series with zero confounders are the best candidates for having a genuine relationship with your time-series data.        `,
      delayAnimation: "300",
  },
];

const MethodologyPage = () => {
    return (
        <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
                <Helmet>
                    <title>LikelySpurious - Methodology</title>
                </Helmet>
                <div className="ptf-site-wrapper__inner">
                    <HeaderDefault />
                    <h2 className="large-heading">Methodology</h2>
                    <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
                    <div className="methodology-container">
                    <ul className="ptf-process-steps">
                      {approachContent.map((val, i) => (
                        <li
                          className="ptf-process-item"
                          key={i}
                        >
                          <h6 className="ptf-process-item__title">{val.title}</h6>
                          <p className="ptf-process-item__text">{val.descriptions}</p>
                        </li>
                      ))}
                    </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MethodologyPage;
