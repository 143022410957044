import React, { useState, useEffect } from "react";
import { usePrecheckout } from '../context/PreCheckoutContext';
import { Navigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const Return = () => {
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const { precheckoutId } = usePrecheckout();
  
    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get('session_id');
  
      fetch(`https://9pz3pqmrru.us-east-2.awsapprunner.com/api/session-status/?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
          checkUserExists(data.customer_email);
        });

        localStorage.removeItem('precheckout_id');
    }, []);

    const checkUserExists = (email) => {
      fetch(`https://9pz3pqmrru.us-east-2.awsapprunner.com/api/check_user_email/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      })
      .then((res) => res.json())
      .then((data) => {
        if (!data.user_exists) {
          console.log(data.user_exists);
          const randomPassword = generateRandomPassword();
          createUser(email, randomPassword);
        }
        console.log(data.user_exists);
      });
    };

    const generateRandomPassword = () => {
      const length = 12;
      const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    };

    const createUser = (email, password) => {
      fetch(`https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          name: "Temp",
          password: password,
          re_password: password,
        }),
      })
      .then((res) => res.json())
      .then((data) => {
        // Handle response for user creation
        console.log("User created successfully:", data);
      })
      .catch((error) => {
        console.error("Failed to create user:", error);
      });
    };

    // Component rendering logic remains the same
    if (status === 'open') {
      return <Navigate to="/checkout" />;
    }
  
    if (status === 'complete') {
      return (
        <div className="checkout-page-container">
        <div className="ptf-site-wrapper animsition  ptf-is--about-us">
          <Helmet>
            <title>LikelySpurious - Confirmation</title>
          </Helmet>
          {/* End Page SEO Content */}
          <div className="ptf-site-wrapper__inner">
            <HeaderDefault />
            <section id="success">
              <div className="success-text">
               We appreciate your business. You should receive an email to {customerEmail} within 24 hours (can take up to 48 hours) that will allow you to set up an account and access your analysis. If you have any questions, please <Link className="normal-link" to="/contact">contact us</Link>.
              </div>
            </section>
        </div>
        </div>
        </div>
      )
    }
  
    return null;
  }

  export default Return;
