import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ActivationPage = () => {
  const { uid, token } = useParams();
  const [activationStatus, setActivationStatus] = useState('');

  useEffect(() => {
    axios.post(`https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/users/activation/`, { uid, token })
      .then(() => {
        setActivationStatus('Your account has been successfully activated. Checking for email...');
        // After successful activation, retrieve the user's email
        getUserEmail();
      })
      .catch(error => {
        console.error('Error activating account:', error);
        setActivationStatus('There was an error activating your account. Please try again or contact support.');
      });
  }, [uid, token]);

  const getUserEmail = () => {
    // Replace with your actual endpoint to get the user's email securely
    axios.get(`https://9pz3pqmrru.us-east-2.awsapprunner.com/api/get-email/${uid}/${token}/`)
      .then(response => {
        const userEmail = response.data.email;
        if (userEmail) {
          triggerPasswordReset(userEmail);
        } else {
          setActivationStatus('Unable to retrieve email. Please contact support.');
        }
      })
      .catch(error => {
        console.error('Error fetching user email:', error);
        setActivationStatus('There was an error retrieving your email. Please contact support.');
      });
  };

  const triggerPasswordReset = (userEmail) => {
    axios.post('https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/users/reset_password/', { email: userEmail })
      .then(() => {
        setActivationStatus('Your account has been activated. A password reset link has been sent to your email.');
      })
      .catch(error => {
        console.error('Error triggering password reset:', error);
        setActivationStatus('Failed to send password reset link. Please contact support.');
      });
  };

  return (
    <div className="page-container">
      <div className="ptf-site-wrapper animsition ptf-is--about-us">
        <Helmet>
          <title>LikelySpurious - Account Activation</title>
        </Helmet>
        <div className="ptf-site-wrapper__inner">
          <HeaderDefault />
          <div className="column" id="left-column">
            <h2 className='main-headline'>Account Activation</h2>
            <h4 className='sub-headline'>{activationStatus}</h4>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ActivationPage;
