import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
  ProSidebar,
  Menu,
  MenuItem,
  // SubMenu,
  SidebarContent,
} from "react-pro-sidebar";
// import Social from "../../social/Social";

const menuContent = [
    {
      name: "Home",
      activeClass: "sf-with-ul",
      menuClass: "two-columns current-menu-item",
      pageRoute: "/",
    },
    {
      name: "Methodology",
      activeClass: "",
      menuClass: "",
      pageRoute: "/methodology",
    },
    {
      name: "About",
      activeClass: "",
      menuClass: "",
      pageRoute: "/about",
    },
    {
      name: "Contact",
      activeClass: "",
      menuClass: "two-columns",
      pageRoute: "/contact",
    },{
      name: "Login",
      activeClass: "",
      menuClass: "two-columns",
      pageRoute: "/login",
    },
];

const MobileMenu = () => {
  return (
    <>
      <div className="ptf-offcanvas-menu__navigation">
        {/* <ProSidebar> */}
          {/* <SidebarContent> */}
            <Menu className="sidebar-menu_wrapper">
            {menuContent.map((item, index) => (
              <MenuItem key={index} className={item.menuClass}>
                <Link to={item.pageRoute}>{item.name}</Link>
              </MenuItem>
            ))}
            </Menu>
          {/* </SidebarContent> */}
        {/* </ProSidebar> */}
      </div>
      {/* End .ptf-offcanvas-menu__navigation */}
    </>
  );
};

export default MobileMenu;
