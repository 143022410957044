import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { AuthProvider } from './context/AuthContext'
import { PreCheckoutProvider } from './context/PreCheckoutContext';

import HomePage from "./pages/HomePage";
import AnalysesListPage from './pages/AnalysesListPage'
import AnalysisPage from "./pages/AnalysisPage";
import LoginPage from './pages/LoginPage'
import CheckoutPage from "./pages/CheckoutPage"
import MethodologyPage from "./pages/MethodologyPage"
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ActivationPage from "./pages/Activation";
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordResetReqPage from "./pages/PasswordResetReqPage";
import NotFoundPage from "./pages/404Page";

import PublicAnalysesListPage from './pages/PublicAnalysesListPage'
import PublicAnalysisPage from "./pages/PublicAnalysisPage";

import Header from './components/Header'

import PrivateRoute from './utils/PrivateRoute'
import Return from './components/Return';

import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "photoswipe/dist/photoswipe.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";

// import "./App.css";

function App() {
    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <PreCheckoutProvider>
                            {/* <Header/> */}
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/checkout" element={<CheckoutPage />} />
                                <Route path="/return" element={<Return />} />
                                <Route path="/about" element={<AboutPage />} />
                                <Route path="/methodology" element={<MethodologyPage />} />
                                <Route path="/contact" element={<ContactPage />} />
                                <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                                <Route path="/activate/:uid/:token" element={<ActivationPage />} />
                                <Route path="/password/reset/confirm/:uid/:token" element={<PasswordResetPage />} />
                                <Route path="/password-reset-request" element={<PasswordResetReqPage />} />
                                <Route path="/public-analyses" element={<PublicAnalysesListPage />}/>
                                <Route path="/public-analysis/:id" element={<PublicAnalysisPage />}/>
                                <Route path="/analyses" element={
                                    <PrivateRoute>
                                        <AnalysesListPage />
                                    </PrivateRoute>}/>
                                <Route path="/analysis/:id" element={
                                    <PrivateRoute>
                                        <AnalysisPage />
                                    </PrivateRoute>}/>
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                    </PreCheckoutProvider>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;