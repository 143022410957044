import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

import { Box, Stack, useTheme } from '@mui/material';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';


const AnnotationsTable = () => {
  const theme = useTheme();

  const headerBackgroundColor = theme.palette.mode === 'dark'
    ? 'rgba(3, 44, 43, 1)'
    : 'white'; // Set to white for light mode

  const groupingHeaderBackgroundColor = 'white'; // Set to white for light mode

  const { user, authTokens, updateToken } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [retryAttempted, setRetryAttempted] = useState(false);
  const { id } = useParams();

  const fetchData = async () => {
    setLoading(true);
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));
    const requestOptions = {
      method: 'POST',
      headers: {
        //'Authorization': `JWT ${authTokens.access}`, // Include the JWT token here
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id }), // Adjust this to dynamically use the required id
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/public-annotations/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
          setRetryAttempted(true); // Mark that a retry attempt has been made
          if (refreshSuccess) {
            fetchData(); // Retry the fetch after refreshing the token
          }
          setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        const fetchedData = await response.json();
        setData(fetchedData.data);
      }
    } catch (error) {
      console.error('Fetch data error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Date Annotation',
        accessorKey: 'date_annotation',
        enableGrouping: false, //do not let this column be grouped
        size: 200, // Set a custom size
      },
      {
        header: 'Dates',
        accessorKey: 'dates',
        size: 200, // Set a custom size
      },
      {
        header: 'Events',
        accessorKey: 'event',
        size: 300, // Set a custom size
        AggregatedCell: ({ cell, table }) => (
          <>
            Events under{' '}
            {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
            <Box
              sx={{ color: 'info.main', display: 'inline', fontWeight: 'bold' }}
            >
              {cell.getValue()}
            </Box>
          </>
        ),
      },
      {
        header: 'Key Points',
        accessorKey: 'key_points',
        size: 700, // Set a custom size
      },
    ],
    [],
  );
  

  return (
    <Box>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <MaterialReactTable
          columns={columns}
          data={data}
          displayColumnDefOptions={{
            'mrt-row-expand': {
              enableResizing: true,
            },
          }}
          enableColumnResizing
          enableGrouping
          enableStickyHeader
          enableStickyFooter
          initialState={{
            density: 'compact',
            expanded: true, //expand all groups by default
            grouping: ['date_annotation'], //group by date_annotation by default
            pagination: { pageIndex: 0, pageSize: 20 },
            sorting: [{ id: 'date_annotation', desc: false }], //sort by date_annotation by default
          }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps={{ sx: { maxHeight: 700, width: '100%' } }}
          muiTableHeadCellProps={{
            sx: {
              backgroundColor: headerBackgroundColor,
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              '&.MuiTableCell-root': {
                backgroundColor: groupingHeaderBackgroundColor,
                wordWrap: 'break-word',
                whiteSpace: 'normal',
              },
            },
          }}
          muiGroupingCellProps={{
            sx: {
              backgroundColor: groupingHeaderBackgroundColor,
            },
          }}
          muiAlertProps={{
            sx: {
              backgroundColor: 'white', // Your desired background color
              color: 'rgba(0, 0, 0, 0.87)', // Your desired text color
            },
          }}
          state={{
            isLoading: loading,
          }}
        />
      )}
    </Box>
  );
};

export default AnnotationsTable;
