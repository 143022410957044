import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext'
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const LoginPage = () => {
    let {loginUser} = useContext(AuthContext)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [loginError, setLoginError] = useState('');

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // const validatePassword = (password) => {
    //     // Basic validation: Password length should be greater than 6 characters
    //     // You can add more complex checks here if needed
    //     return password.length > 6;
    // };

    const validatePassword = (password) => {
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumbers = /\d/.test(password);
      // const hasNonalphas = /\W/.test(password);
      const isLongEnough = password.length >= 8;
  
      return hasUpperCase && hasLowerCase && hasNumbers && isLongEnough;
    };

    const preLoginUser = async (event) => {
      event.preventDefault();
      setEmailValid(validateEmail(email));
      setPasswordValid(validatePassword(password));

      if (validateEmail(email) && validatePassword(password)) {
          const result = await loginUser(email, password);
          if (!result.success) {
              setLoginError(result.message); // Set the error message
          } else {
              console.log("Login Successful");
              setLoginError(''); // Clear any previous error messages
          }
      }
  };

  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Login</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <h2 className="small-heading">Login</h2>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "2.5rem" }}
                  ></div>
                  <div className="login-container">
                    <form className="login-form" onSubmit={(event) => preLoginUser(event)}>
                    <input 
                        type="text" 
                        name="email" 
                        placeholder="Enter email" 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {!emailValid && <div className="error">Please enter a valid email</div>}

                    <input 
                        className="password-field" 
                        type="password" 
                        name="password" 
                        placeholder="Enter password" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {!passwordValid && <div className="error">Password must be at least 8 characters long and include uppercase, lowercase, and number.</div>}
                    {loginError && <div className="error">{loginError}</div>}
                    <input className='form-button' type="submit"/>
                </form>
                <div className="password-reset-link">
                  <Link to="/password-reset-request">Forgot password?</Link>
                </div>
        </div>
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default LoginPage;
