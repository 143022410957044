import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {

    let [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null))
    let [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null))
    let [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    let loginUser = async (email, password) => {
        const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/jwt/create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
        });

        if (response.status === 429) {
            return { success: false, message: 'Too many login attempts. Please try again later.' };
        }

        let data = await response.json();

        if(data){
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data)
            try {
                setUser(jwtDecode(data.access))
                navigate('/analyses')
                return "Login Successful";
              } catch(error) {
                console.log("Caught jwt error!")
                localStorage.removeItem('authTokens')
                return { success: false, message: 'Invalid email or password' };
              }
        } else {
            console.log('Something went wrong while logging in the user!')
            alert('Something went wrong while logging in the user!')
        }
    }

    let logoutUser = () => {
        // e.preventDefault()
        localStorage.removeItem('authTokens')
        setAuthTokens(null)
        setUser(null)
        navigate('/login')
    }

    const updateToken = async () => {
        const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/jwt/refresh/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify({refresh: authTokens?.refresh})
        })
       
        const data = await response.json()
        if (response.status === 200) {
            console.log("200 response")
            const updatedTokens = {
                ...authTokens, // Spread existing tokens to retain the refresh token
                access: data.access, // Update the access token
            };
            localStorage.setItem('authTokens', JSON.stringify(updatedTokens));
            setAuthTokens(updatedTokens);
            setUser(jwtDecode(data.access));
        } else {
            logoutUser()
        }

        if(loading){
            setLoading(false)
        }
    }

    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        updateToken: updateToken,
    }

    useEffect(()=>{
        if(loading){
            updateToken()
        }

        const REFRESH_INTERVAL = 1000 * 60 * 120 // 120 minutes
        let interval = setInterval(()=>{
            if(authTokens){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)

    },[authTokens, loading])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}