import React from 'react';
import '../App.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <p className="main-footer">
      <span className="all-rights-footer">&copy; 2024 LikelySpurious. All rights reserved.</span> 
      <span className="privacy-footer"><Link to="/privacy-policy">Privacy</Link></span> <span className="terms-footer"><Link to="/terms-of-service">Terms</Link></span> </p>
    </footer>
  );
};

export default Footer;