import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import AuthContext from '../context/AuthContext'
import Papa from 'papaparse';

import { Helmet } from "react-helmet";
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";
import YoutubeEmbed from "../components/YoutubeEmbed";

const HomePage = () => {
    const [first10Rows, setFirst10Rows] = useState([]);
    const [last10Rows, setLast10Rows] = useState([]);
    const [columnOne, setColumnOne] = useState([]);
    const [columnTwo, setColumnTwo] = useState([]);
    const [dataValidationError, setDataValidationError] = useState([]);
    const [file, setFile] = useState();
    const [showCheckoutButton, setShowCheckoutButton] = useState(false);
    const [dataValidationMessage, setDataValidationMessage] = useState([]);
    const [dataFrameLength, setDataFrameLength] = useState([]);
    const [inferFreq, setInferFreq] = useState([]);
    const [seriesName, setSeriesName] = useState('');

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/checkout", { state: { "first10Rows": first10Rows, 
                                         "last10Rows": last10Rows,
                                         "file": file,
                                         "inferFreq": inferFreq,
                                         "dataFrameLength": dataFrameLength,
                                         "seriesName": seriesName} });
      };
    

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setDataValidationError([]);
        setShowCheckoutButton(false);
        setColumnOne("");
        setColumnTwo("");
        setFirst10Rows([]);
        setLast10Rows([]);
        setDataValidationMessage([]);
        setDataFrameLength([]);
        setInferFreq([]);
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (file) {
            const fileReader = new FileReader();
      
            fileReader.onload = () => {
              const fileContent = fileReader.result;
              const firstLine = fileContent.split('\n')[0];
              const columnNames = firstLine.split(',');
              setSeriesName(columnNames[1]);
              validateCSV(fileContent)
            };
      
            fileReader.readAsText(file);

            const formData = new FormData();
            formData.append('csv_file', file);

            fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/upload-csv/', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                setDataValidationMessage(data["message"])
                setDataFrameLength(data["length"])
                setInferFreq(data["frequency"])
                console.log('Upload successful:', data["message"]);
                if (data["message"].includes("passed")) {
                    setShowCheckoutButton(true);
                  } else {
                    setShowCheckoutButton(false);
                  }
                // Handle success
                })
                .catch(error => {
                console.error('Error during upload:', error);
                // Handle error
                });
          }
    };

    const validateCSV = (csvData) => {
        Papa.parse(csvData, {
          header: false,
          dynamicTyping: true,
          skipEmptyLines: true,
          complete: (results) => {
            processValidationResults(results.data);
          },
        });
      };
    
      const processValidationResults = (csvRows) => {
        const validationResults = csvRows.slice(1).map((row) => {
          const dateValue = new Date(row[0]);
          const isDateValid = !isNaN(dateValue.getTime());
          const isNumeric = !isNaN(parseFloat(row[1])) && isFinite(row[1]);
    
          return {
            dateValid: isDateValid,
            numericValid: isNumeric,
            rowData: row,
          };
        });
    
        // Filter results where either dateValid or numericValid is false
        const invalidResults = validationResults.filter(
            (result) => !result.dateValid || !result.numericValid
        );

        let nextId = 0
        const cleanDataForDisplay = csvRows.map((row) => { 
            const newRowOne = typeof row[0] === 'string' ? row[0].replace(/[\[\]']+/g,'') : String(row[0]).replace(/[\[\]']+/g,'');
            const newRowTwo = parseFloat(row[1])

            return {
                id: nextId++,
                date: newRowOne,
                y: newRowTwo,
              } });

        if (invalidResults.length == 0) {
            console.log('No invalid results:', invalidResults);
            setFirst10Rows(cleanDataForDisplay.slice(0, 10));
            setLast10Rows(cleanDataForDisplay.slice(-10));
            setColumnOne(csvRows.slice(0, 1)[0][0]);
            setColumnTwo(csvRows.slice(0, 1)[0][1]);
            setDataValidationError([]);
        }
        else {
            setDataValidationError("Issue with your CSV. Please read the CSV requirements")
            setColumnOne("");
            setColumnTwo("");
            setFirst10Rows([]);
            setLast10Rows([]);
            setShowCheckoutButton(false);
            console.log('Invalid results:', invalidResults);
        }
      };


    return (
      <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Homepage</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <div className="columns-container">
                  <div className="column" id="left-column">
                    {/* Content for the left column */}
                    <h2 className='main-headline'>Better understand your world for $10</h2>
                    <h4 className='sub-headline'>You upload time series data, we compare it with up to 1 million time series datasets and let you know what series potentially influence yours. Though, the relationships are likely spurious.</h4>
                    <div className="file-input-container">
                      <form>
                        <input type="file" id="file-input" accept={".csv"} onChange={handleOnChange} />
                        <button className='csv-button' onClick={(e) => {handleOnSubmit(e);}}>Upload CSV</button>
                      </form>
                    </div>
                    <div>{dataValidationError}</div>
                    <p className='file-requirements'>File must be a CSV no larger than 1MB that contains two columns. First column contains dates as rows (we can process several date formats). Second column contains your series of interest. Recommend including a descriptive column name for your second column as it will be used to determine potential events that influenced shocks in your series. Date frequencies accepted: daily, weekly, monthly, quarterly (Jan., Apr., Jul., Oct.), and annual. Example format below:</p>            
                    {first10Rows.length == 0 && (
                    <>
                      <div className='example-table-text'> Example: </div>
                      <table className='data-preview-table'>
                        <thead>
                          <tr>
                            <th className="data-preview-first-column">Date</th>
                            <th className="data-preview-second-column">Y</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td>2011-01-01</td>
                              <td>34223</td>
                            </tr>
                            <tr>
                              <td>2011-04-01</td>
                              <td>38342</td>
                            </tr>
                            <tr>
                              <td>2011-07-01</td>
                              <td>39234</td>
                            </tr>
                            <tr>
                              <td>2011-10-01</td>
                              <td>40211</td>
                            </tr>
                            <tr>
                              <td>2012-01-01</td>
                              <td>41893</td>
                            </tr>
                            <tr>
                              <td>2012-04-01</td>
                              <td>46321</td>
                            </tr>
                        </tbody>
                      </table> 
                    </>)}
                    {first10Rows && first10Rows.length > 0 && (
                      <>
                      <div className='dataframe-length'>Series length: {dataFrameLength} </div>
                      <div className='dataframe-length'>Detected frequency: {inferFreq} </div>
                      <table className='data-preview-table'>
                        <thead>
                          <tr>
                            <th>{columnOne}</th>
                            <th>{columnTwo}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {first10Rows.slice(1).map((row) => (
                            <tr key={row.id}>
                              <td>{row.date}</td>
                              <td>{row.y}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='ellipsis'>...</div>
                      </>
                    )}
                    {last10Rows && (<table className='data-preview-table'>
                        <tbody>
                        {last10Rows.slice(1).map((row) => (
                            <tr key={row.id}>
                            <td>{row.date}</td>
                            <td>{row.y}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>)}
                    <div className='validation-checks-message'>{dataValidationMessage}</div>
                    <div>
                    {showCheckoutButton && (
                        <button className='csv-button' onClick={handleButtonClick}>Proceed to Checkout</button>
                    )}
                    </div>
                  </div>
                  <div className="column" id="right-column">
                    {/* Content for the right column */}
                    <YoutubeEmbed embedId="PpsGhYsJtf8?si=MApCLRiZbxaU8OHC" />
                    <div className='text-container'>
                      <div className='text-box'>LikelySpurious is an educational and research application that uses classical and advanced causal modeling techniques to detect linear and (select) non-linear relationships.</div>
                      <hr class="divider"></hr>
                      <div className='text-box'>Economic, news ngram, and book ngram series comprise the majority of the datasets. Weather series coming soon. <strong className="highlighted-text">LikelySpurious does not provide you with any data</strong>, while most data is in the public domain, you will need to access it from the direct source (we provide links).</div>
                      <hr class="divider"></hr>
                      <div className='text-box'>We try our best to keep the datasets as up to date as possible but the analysis is not meant to detect real time and/or rapidly developing relationships, but rather long term dependencies.</div>
                      <hr class="divider"></hr>
                      <div className='text-box'>The output is a list of candidate time series. Your data or the series being compared to will be resampled (e.g. monthly to quarterly) to match frequencies. Your data will be transformed to meet modeling requirements (e.g. stationarity).</div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            <Footer />
      </div>
    )
}

export default HomePage
