import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { Helmet } from "react-helmet";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

import Plot from 'react-plotly.js';

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv'; //or use your library of choice here

import SourcesModule from "../components/Sources"
// import AnalysisTableNP from "../components/AnalysisTableNP"
import AnnotationsTable from '../components/AnnotationsTable';

const AnalysisPage = () => {
  const { user, authTokens, updateToken } = useContext(AuthContext); // Use authTokens and updateToken from context
  const { id } = useParams();
  const [analyses, setAnalyses] = useState([]);
  // const [sourceData, setSourceData] = useState([]);
  const [analysesList, setAnalysesList] = useState([]);
  const [depSeriesName, setDepSeriesName] = useState([]);
  const [ySeries, setYSeries] = useState([]);
  const [xSeries, setXSeries] = useState([]);
  const [dateSeries, setDateSeries] = useState([]);
  const [indepSeriesName, setIndepSeriesName] = useState([]);
  const [indepSeriesID, setIndepSeriesID] = useState([]);
  const [plotWidth, setPlotWidth] = useState(window.innerWidth);
  const [selectedLeftLag, setSelectedLeftLag] = useState(0);
  const [selectedRightLag, setSelectedRightLag] = useState(1);
  const [leftTransformationType, setLeftTransformationType] = useState('None');
  const [rightTransformationType, setRightTransformationType] = useState('None');
  const [sourceURL, setSourceURL] = useState([]);
  const [loading, setLoading] = useState(true);
  const [retryAttempted, setRetryAttempted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [annotations, setAnnotations] = useState([]);
  const minDate = Math.min(...dateSeries.map(date => new Date(date).getTime()));
  const maxDate = Math.max(...dateSeries.map(date => new Date(date).getTime()));
  const [recessionAnalyses, setRecessionAnalyses] = useState([]);
  const [loadingRecession, setLoadingRecession] = useState(true);
  
  const truncateIndepSeriesName = (series_name) => {
    const truncatedSeriesName = series_name.length > 150 
      ? `${series_name.slice(0, 150)}...` 
      : series_name;
    setIndepSeriesName(truncatedSeriesName);
  };


  const filteredAnnotations = annotations.filter(annotation => {
    const annotationDate = new Date(annotation.date_annotation).getTime();
    return annotationDate >= minDate && annotationDate <= maxDate;
  });

  const columns = useMemo(() => [
    { accessorKey: 'series_name', header: 'Series Name', minSize: 250, maxSize: 260},
    {
      id: 'plot',
      header: 'Plot',
      minSize: 30,
      maxSize: 40,
      accessorFn: (row) => row.series_name,
      Cell: ({ row }) => (
        <button 
          className='csv-button' 
          onClick={() => {
            setCurrentIndex(row.index);  // Update currentIndex to the index of this row
            fetchMatchedSeriesData(analyses, row.original.indepen, row.original.series_name, row.original.source_url);
          }}>Plot</button>)
    },    
    { accessorKey: 'num_confounders', columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], filterFn: 'between', header: 'Number of Confounders', minSize: 30, maxSize: 40},
    { accessorKey: 'sig_lags', columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], filterFn: 'between', header: 'Significant Lags', minSize: 30, maxSize: 40},
    { accessorKey: 'sig_lags_bi', columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], filterFn: 'between', header: 'Significant Lags Bi-Directional', minSize: 200, maxSize: 205},
    { accessorKey: 'min_date', columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], filterFn: 'between', header: 'Min Date', minSize: 30, maxSize: 40},
    { accessorKey: 'max_date', columnFilterModeOptions: ['between', 'greaterThan', 'lessThan'], filterFn: 'between', header: 'Max Date', minSize: 30, maxSize: 40},
    { accessorKey: 'cointegration', header: 'Cointegration', minSize: 30, maxSize: 40, Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No'},
    { accessorKey: 'confounders', header: 'Confounders', minSize: 50, maxSize: 70},
    { accessorKey: 'frequency', header: 'Frequency', minSize: 50, maxSize: 70},
    {
      id: 'source_url',
      header: 'Source URL',
      minSize: 30,
      maxSize: 40,
      accessorFn: (row) => row.source_url,
      Cell: ({ cell }) => <button className='csv-button' onClick={() => window.open(cell.row.original.source_url, '_blank', 'noopener,noreferrer')}>Source</button>
    }
  ], [analyses]);
  
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
    filename: 'likelyspurious_export',
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(analyses);
    download(csvConfig)(csv);
  };


  const handlePreviousSeries = () => {
    // Check if the previous index is within the array bounds
    if (currentIndex - 1 >= 0) {
        const previousRow = analyses[currentIndex - 1];
        if (previousRow) {
            fetchMatchedSeriesData(analyses, previousRow.indepen, previousRow.series_name, previousRow.source_url);
            setCurrentIndex(currentIndex - 1);
        }
    } else {
        console.log("You've reached the beginning of the list.");
    }
};

  const handleNextSeries = () => {
    // Check if next index is within the array bounds
    if (currentIndex + 1 < analyses.length) {
      const nextRow = analyses[currentIndex + 1];
      if (nextRow) {
        fetchMatchedSeriesData(analyses, nextRow.indepen, nextRow.series_name, nextRow.source_url);
        setCurrentIndex(currentIndex + 1);
      }
    } else {
      console.log("You've reached the end of the list.");
      // Optional: Wrap around to the first index
      // const firstRow = analyses[0];
      // fetchMatchedSeriesData(analyses, firstRow.indepen, firstRow.series_name, firstRow.source_url);
    }
  };
  

  const table = useMaterialReactTable({
    columns,
    data: analyses,
    getRowId: (row) => row.id, // assuming each analysis has a unique id
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
      </Box>
    ),
  });

  const recessionTable = useMaterialReactTable({
    columns,
    data: recessionAnalyses,
    getRowId: (row) => row.id,
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap' }}>
        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>
      </Box>
    ),
  });

  // const table = useMaterialReactTable({
  //   columns,
  //   data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  // });

  const fetchData = async () => {
    setLoading(true);
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));
    
    const requestOptions = {
      method: 'POST',
      headers: {
          'Authorization': `JWT ${authTokens.access}`, // Include the JWT token here
          'Content-Type': 'application/json',
        },
      body: JSON.stringify({ id: id })
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/analysis/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
          setRetryAttempted(true); // Mark that a retry attempt has been made
          if (refreshSuccess) {
            fetchData(); } // Retry the fetch after refreshing the token
            setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      }
      // const data = await response.json();
      const data = await response.json();

      // Assuming the response structure is { data: [array of analyses], dependent_series_name: 'some value' }
      if(data.data) {
        setAnalyses(data.data);
      }
      if(data.dependent_series_name) {
        setDepSeriesName(data.dependent_series_name); // Set dependent series name
      }
      return data; // Return the fetched data
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      return []; // Return an empty array in case of an error
    } 
    finally {
      setLoading(false); // End loading regardless of outcome
    }
  };


  // New function for fetching recession data
  const fetchRecessionData = async () => {
    setLoadingRecession(true);
    const requestOptions = {
      method: 'POST',
      headers: {
          'Authorization': `JWT ${authTokens.access}`, // Include the JWT token here
          'Content-Type': 'application/json',
        },
      body: JSON.stringify({ id: id })
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/analysis-recession/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken();
          setRetryAttempted(true);
          if (refreshSuccess) {
            fetchRecessionData();
          }
          setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      }
      const data = await response.json();
      if (data.data) {
        setRecessionAnalyses(data.data);
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    } finally {
      setLoadingRecession(false);
    }
  };

  const fetchAnnotationsData = async () => {
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': `JWT ${authTokens.access}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id }),
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/annotations/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
          setRetryAttempted(true); // Mark that a retry attempt has been made
          if (refreshSuccess) {
            fetchAnnotationsData(); // Retry the fetch after refreshing the token
          }
          setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        const fetchedData = await response.json();
        console.log(fetchedData.data)
        setAnnotations(fetchedData.data);

      }
    } catch (error) {
      console.error('Fetch data error:', error);
    } 
  };

  // const fetchSourceData = async () => {
  //   const authTokens = JSON.parse(localStorage.getItem('authTokens'));
    
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: {
  //         'Authorization': `JWT ${authTokens.access}`, // Include the JWT token here
  //         'Content-Type': 'application/json',
  //       }
  //   };

  //   try {
  //     const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/data-sources/', requestOptions);
  //     if (!response.ok) {
  //       if (response.status === 401 && !retryAttempted) {
  //         const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
  //         setRetryAttempted(true); // Mark that a retry attempt has been made
  //         if (refreshSuccess) {
  //           fetchSourceData(); } // Retry the fetch after refreshing the token
  //           setRetryAttempted(false);
  //       } else {
  //         throw new Error('Network response was not ok');
  //       }
  //     }
  //     // const data = await response.json();
  //     const source_data = await response.json();

  //     // Assuming the response structure is { data: [array of analyses], dependent_series_name: 'some value' }
  //     if(source_data.source_data) {
  //       setSourceData(source_data.source_data);
  //     }
  //     return source_data.source_data; // Return the fetched data
  //   } catch (error) {
  //     console.error('There has been a problem fetching source data:', error);
  //     return []; // Return an empty array in case of an error
  //   } 
  // };



  const fetchMatchedSeriesData = async (analyses, series_id, series_name, series_url) => {
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));

    if (series_id === "") {
        console.log("series_id is an empty string");
        series_id = analyses[0].indepen
    } 
  
    const requestOptions = {
      method: 'POST',
      headers: {
          'Authorization': `JWT ${authTokens.access}`, // Include the JWT token here
          'Content-Type': 'application/json',
        },
      body: JSON.stringify({ id: id,  series_id: String(series_id) })
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/dep-indep-series-match/', requestOptions);
      if (!response.ok) {
        if (response.status === 401 && !retryAttempted) {
          const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
          setRetryAttempted(true); // Mark that a retry attempt has been made
          if (refreshSuccess) {
            fetchData(); } // Retry the fetch after refreshing the token
            setRetryAttempted(false);
        } else {
          throw new Error('Network response was not ok');
        }
      }
      const data = await response.json();
      const dates = data.matched_series.map(item => item.date);
      const xs = data.matched_series.map(item => item.x);
      const ys = data.matched_series.map(item => item.y);
      setXSeries(xs);
      setYSeries(ys);
      setDateSeries(dates);
      // setIndepSeriesName(`${series_name.slice(0,120)}... (ID: ${series_id})`);
      //setIndepSeriesName(`${series_name.slice(0,150)}`);
      truncateIndepSeriesName(series_name)
      setIndepSeriesID(series_id)
      setSourceURL(series_url)
      console.log(series_url)
      console.log(sourceURL)
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  };

  const processData = (series, transformation) => {
    if (transformation === 'Difference') {
      // Calculate difference for each subsequent pair of elements
      return series.map((value, index) => index > 0 ? value - series[index - 1] : null).filter(value => value !== null);
    } else if (transformation === 'Percent Change') {
      // Calculate percent change for each subsequent pair of elements
      return series.map((value, index) => index > 0 ? (value - series[index - 1]) / series[index - 1] * 100 : null).filter(value => value !== null);
    }
    return series; // If 'None', return the series as-is
  };
  
    // Use the processData function to transform xSeries and ySeries
    const transformedLeftXSeries = processData(xSeries, leftTransformationType);
    const transformedLeftYSeries = processData(ySeries, leftTransformationType);

    const transformedRightXSeries = processData(xSeries, rightTransformationType);
    const transformedRightYSeries = processData(ySeries, rightTransformationType);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem('authTokens'));

    const handleResize = () => {
      setPlotWidth(window.innerWidth);
      };

    window.addEventListener('resize', handleResize);

    const loadData = async () => {
      await fetchData(); // Wait for fetchData to finish
      await fetchRecessionData();
      if (analyses.length > 0) {
        fetchMatchedSeriesData(analyses, analyses[0].indepen, analyses[0].series_name, analyses[0].series_url); // Then call fetchMatchedSeriesData
      }
    };

    fetchAnnotationsData();

    loadData();

    // fetchSourceData();

  }, [user]); // Make sure to include any dependencies here


  return (
    user ? (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Analysis</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
                <h2>Analysis - {depSeriesName}</h2>
                <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
                <h3 className='main-plot-title'>{depSeriesName} vs {indepSeriesName}</h3>
                <Plot
                  data={[
                    {
                      x: dateSeries,
                      y: xSeries,
                      type: 'scatter',
                      // name: indepSeriesID,
                      name: `${indepSeriesName.slice(0,50)}...`,
                      yaxis: 'y1',
                      marker: {
                        color: 'grey' // Color for the first series
                      },
                    },
                    {
                      x: dateSeries,
                      y: ySeries,
                      type: 'scatter',
                      name: depSeriesName,
                      yaxis: 'y2', // Assign to the secondary y-axis
                      marker: {
                        color: '#4a8c8a' // Color for the second series
                      },
                    },
                    {
                      x: filteredAnnotations.map(annotation => annotation.date_annotation), // X values for filtered annotations
                      y: filteredAnnotations.map(annotation => {
                        const index = dateSeries.indexOf(annotation.date_annotation);
                        return ySeries[index]; // Corresponding Y values from ySeries
                      }),
                      yaxis: 'y2',
                      type: 'scatter',
                      mode: 'markers',
                      name: 'Annotations',
                      marker: {
                        color: '#4a8c8a',
                        size: 7 // Size of the annotation markers
                      },
                    },
                  ]}
                  layout={{
                    width: (plotWidth * .85),
                    height: 500,
                    yaxis: {
                      title: `${indepSeriesName.slice(0,40)}...`
                    },
                    yaxis2: {
                      title: depSeriesName,
                      overlaying: 'y',
                      side: 'right',
                    },
                    showlegend: true,
                    legend: {
                      orientation: 'h',
                      x: 0.5,
                      y: -0.2,
                      xanchor: 'center',
                      yanchor: 'top'
                    }
                  }}
                />

              <button className='csv-button source-button' onClick={() => window.open(sourceURL, '_blank', 'noopener,noreferrer')}>Source</button>
              <button className='csv-button source-button next-button' onClick={handlePreviousSeries}>Prev</button>
              <button className='csv-button source-button next-button' onClick={handleNextSeries}>Next</button>
              <h3 className='main-plot-title'>{depSeriesName} vs {indepSeriesName}</h3>
              <div className="scatterplot-container">

              <div className='dropdown-container'>
                <div className="plot-with-selector">
                    <div className="lag-selector">
                      <label htmlFor="lag-select">X Lag: </label>
                      <select className="lag-select" value={selectedLeftLag} onChange={(e) => setSelectedLeftLag(Number(e.target.value))}>
                        {[0, 1, 2, 3, 4, 5].map(lag => (
                          <option key={lag} value={lag}>{lag}</option>
                        ))}
                      </select>
                    </div>

                    <div className="transformation-selector">
                    <label htmlFor="transformation-select">Transformation: </label>
                    <select id="transformation-select" value={leftTransformationType} onChange={(e) => setLeftTransformationType(e.target.value)}>
                      {['None', 'Difference', 'Percent Change'].map(type => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                </div>
                  
                <Plot
                  data={[
                    {
                      x: selectedLeftLag === 0 ? transformedLeftXSeries : transformedLeftXSeries.slice(0, -selectedLeftLag),
                      y: selectedLeftLag === 0 ? transformedLeftYSeries : transformedLeftYSeries.slice(selectedLeftLag),
                      mode: 'markers',
                      type: 'scatter',
                      name: `${indepSeriesName.slice(0,50)}...`,
                      marker: {
                        color: 'grey',
                      }
                    }
                  ]}
                  layout={ {width: ((plotWidth*.85)/2), height: 500, xaxis: {title: `${indepSeriesName.slice(0,50)}...`}, yaxis: {title: depSeriesName},
                }}
                />
                </div>
                <div className='dropdown-container'>
                <div className="plot-with-selector">
                    <div className="lag-selector">
                      <label htmlFor="lag-select">X Lag: </label>
                      <select className="lag-select" value={selectedRightLag} onChange={(e) => setSelectedRightLag(Number(e.target.value))}>
                        {[0, 1, 2, 3, 4, 5].map(lag => (
                          <option key={lag} value={lag}>{lag}</option>
                        ))}
                      </select>
                    </div>

                    <div className="transformation-selector">
                    <label htmlFor="transformation-select">Transformation: </label>
                    <select id="transformation-select" value={rightTransformationType} onChange={(e) => setRightTransformationType(e.target.value)}>
                      {['None', 'Difference', 'Percent Change'].map(type => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <Plot
                  data={[
                    {
                      x: selectedRightLag === 0 ? transformedRightXSeries : transformedRightXSeries.slice(0, -selectedRightLag),
                      y: selectedRightLag === 0 ? transformedRightYSeries : transformedRightYSeries.slice(selectedRightLag),
                      mode: 'markers',
                      type: 'scatter',
                      name: `${indepSeriesName.slice(0,50)}...`,
                      marker: {
                        color: '#4a8c8a',
                      }
                    }
                  ]}
                  layout={ {width: ((plotWidth*.85)/2), height: 500, xaxis: {title: `${indepSeriesName.slice(0,50)}...`}, yaxis: {title: depSeriesName},
                 }}
                />
                </div>
                </div>

                <h2>Series available to plot</h2>
                {loading ? (
                  <div>Loading data...</div>
                ) : (
                  <div className="fixed-height-container">
                      <MaterialReactTable table={table} />
                  </div>

                )}

            <h2>Excludes Recessions</h2>
            {loading ? (
                  <div>Loading data...</div>
                ) : (
                  <div className="fixed-height-container">
                      <MaterialReactTable table={recessionTable} />
                  </div>

                )}
            {/* <AnalysisTableRecession /> */}

            <h2>Annotations</h2>
            <AnnotationsTable />


            {/* <AnalysisTableNP /> */}

            <SourcesModule />
            </div>
            </div>
            <Footer />
            </div>)

: null
  );
};

export default AnalysisPage;
