import React from "react";
import { Helmet } from "react-helmet";

import ServiceListThree from "../components/service/ServiceListThree";
import Faq from "../components/faq/Faq";
import Skills from "../components/skill/Skills";
import Approach2 from "../components/service/Approach2";
import CounterThree from "../components/counter/CounterThree";
import ImageGridFour from "../components/image-grid/ImageGridFour";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const AboutPage = () => {
  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - About</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                  {/* <!--Spacer--> */}
                  <h3 className="large-heading">404 - Not Found</h3>
                  <h3 className="large-heading">Sorry, the page you are looking for does not exist.</h3>
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default AboutPage;
