import React from "react";
import { Helmet } from "react-helmet";

import ServiceListThree from "../components/service/ServiceListThree";
import Faq from "../components/faq/Faq";
import Skills from "../components/skill/Skills";
import Approach2 from "../components/service/Approach2";
import CounterThree from "../components/counter/CounterThree";
import ImageGridFour from "../components/image-grid/ImageGridFour";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const AboutPage = () => {
  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - About</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <h2 className="large-heading">About Likely Spurious</h2>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "2.5rem" }}
                  ></div>
                  <p className="fz-18">
                  In 2023 a product manager by profession and a data scientist by training passionate about time-series data decided to build an educational and research application, Likely Spurious, to answer the question: what time series potentially influence the data I am interested in? Neither the question or the process to answer it were as straightforward as anticipated. The result is an application that takes an daily, weekly, monthly, quarterly or annual time series and computes various dependence metrics to detect time-based causality, then uses modern causal modeling techniques to filter out series that are spuriously correlated due to common confounders (e.g. inflation, population). Subjective process and modeling decisions are incorporated into the process in order to make the application applicable to a wide array of time series data; some of the details can be found in our <a className="ls-ahref" href="/methodology">Methodology page</a>. Despite this early version of the application leaving much to be desired, the results are encouraging (to us at least), though likely spurious.
                  </p>
                  <p className="fz-18">Head over to our <a className="ls-ahref" href="/methodology">Methodology page</a> for an overview of the steps the analysis consists of and to the data page (coming soon) to learn about what type of series your data is evaluated against. Perhaps most importantly, view a sample analysis at our <a className="ls-ahref" href="/public-analyses">Public Analyses page</a>
.
                  </p>
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default AboutPage;
