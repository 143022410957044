import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { Helmet } from "react-helmet";

// import ServiceListThree from "../components/service/ServiceListThree";
// import Faq from "../components/faq/Faq";
// import Skills from "../components/skill/Skills";
// import Approach2 from "../components/service/Approach2";
// import CounterThree from "../components/counter/CounterThree";
// import ImageGridFour from "../components/image-grid/ImageGridFour";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const PublicAnalysesListPage = () => {
  const { user, authTokens, updateToken } = useContext(AuthContext); // Use authTokens and updateToken from context
  const [analyses, setAnalyses] = useState([]);
  const [retryAttempted, setRetryAttempted] = useState(false);

  const fetchData = async () => {
    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/api/public-analyses/', {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        setAnalyses(data);
      } else if (response.status === 401 && !retryAttempted) {
        const refreshSuccess = await updateToken(); // Assume updateToken() returns true on success, false otherwise
        setRetryAttempted(true); // Mark that a retry attempt has been made
        if (refreshSuccess) {
          fetchData(); } // Retry the fetch after refreshing the token
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Add an empty dependency array to ensure it runs only once

  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Public Analyses</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
                <h2>Public Analyses</h2>
                <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '18rem' }}>Date and Time</th>
                      <th>Series Name</th>
                      <th style={{ width: '12rem' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {analyses.map((analysis, index) => (
                      <tr id={analysis.id} key={analysis.id}>
                        <td>
                            <a href={`/public-analysis/${analysis.id}`} rel="noopener noreferrer">
                            {new Date(analysis.datetime).toLocaleString()}
                            </a>
                        </td>
                        <td>
                          <a href={`/public-analysis/${analysis.id}`} rel="noopener noreferrer">
                            {analysis.series_name}
                          </a>
                          </td>
                        <td>
                            {analysis.analysis_status
                              .split('_')
                              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                              .join(' ')}
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
            </div>
            </div>
            <Footer />
        </div>)
};

export default PublicAnalysesListPage;
