import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    activeClass: "sf-with-ul",
    menuClass: "two-columns current-menu-item",
    pageRoute: "/",
  },
  {
    name: "Public Analyses",
    activeClass: "",
    menuClass: "",
    pageRoute: "/public-analyses",
  },
  {
    name: "Methodology",
    activeClass: "",
    menuClass: "",
    pageRoute: "/methodology",
  },
  {
    name: "About",
    activeClass: "",
    menuClass: "",
    pageRoute: "/about",
  },
  {
    name: "Contact",
    activeClass: "",
    menuClass: "two-columns",
    pageRoute: "/contact",
  },
];

const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li className={`menu-item-has-children ${item.menuClass}`} key={i}>
          <a href={item.pageRoute} className={item.activeClass}>
            <span>{item.name}</span>
          </a>

          {/* <ul className="sub-menu mega">
            {item.dropDownItems.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath}>
                  {" "}
                  <span>{val.name}</span>
                </Link>
              </li>
            ))}
          </ul> */}
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;
