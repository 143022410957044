import React, { createContext, useContext, useState, useEffect } from 'react';

const PrecheckoutContext = createContext();

export const PreCheckoutProvider = ({ children }) => {
  const [precheckoutId, setPrecheckoutId] = useState(null);

  useEffect(() => {
    const newPrecheckoutId = `precheckout_${Date.now()}`;
    setPrecheckoutId(newPrecheckoutId);
    // Save to local storage
    localStorage.setItem('precheckout_id', newPrecheckoutId);
    
    // // Check local storage for existing precheckout_id
    // const storedPrecheckoutId = localStorage.getItem('precheckout_id');
    // if (storedPrecheckoutId) {
    //   setPrecheckoutId(storedPrecheckoutId);
    // } else {
    //   // If no precheckout_id exists, generate a new one
    //   const newPrecheckoutId = `precheckout_${Date.now()}`;
    //   setPrecheckoutId(newPrecheckoutId);
    //   // Save to local storage
    //   localStorage.setItem('precheckout_id', newPrecheckoutId);
    // }
  }, []);

  return (
    <PrecheckoutContext.Provider value={{ precheckoutId }}>
      {children}
    </PrecheckoutContext.Provider>
  );
};

export const usePrecheckout = () => {
  const context = useContext(PrecheckoutContext);
  if (!context) {
    throw new Error('usePrecheckout must be used within a PrecheckoutProvider');
  }
  return context;
};