import React from "react";
import { Helmet } from "react-helmet";

// import ServiceListThree from "../components/service/ServiceListThree";
// import Faq from "../components/faq/Faq";
// import Skills from "../components/skill/Skills";
// import Approach2 from "../components/service/Approach2";
// import CounterThree from "../components/counter/CounterThree";
// import ImageGridFour from "../components/image-grid/ImageGridFour";

import ContactForm from "../components/ContactForm";
import Address from "../components/Address";

import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

const ContactPage = () => {
  return (
    <div className="page-container">
            <div className="ptf-site-wrapper animsition  ptf-is--about-us">
              <Helmet>
                <title>LikelySpurious - Contact</title>
              </Helmet>
              {/* End Page SEO Content */}
              <div className="ptf-site-wrapper__inner">
                <HeaderDefault />
                {/* End  HeaderHomeDefault */}
                <h1 className="large-heading">Contact Likely Spurious</h1>
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "2.5rem" }}
                  ></div>
                  <ContactForm />
            </div>
            </div>
            <Footer />
        </div>
  );
};

export default ContactPage;
