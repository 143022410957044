import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HeaderDefault from "../components/header/HeaderDefault";
import Footer from '../components/Footer';
import "../App.css";

function PasswordResetPage() {
  const { uid, token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    // const hasNonalphas = /\W/.test(password);
    const isLongEnough = password.length >= 8;

    return hasUpperCase && hasLowerCase && hasNumbers && isLongEnough;
  };


  const validateForm = () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return false;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and include uppercase, lowercase, and number.');
      return false;
    }

    // if (password.length < 8) {
    //   setError('Password must be at least 8 characters long.');
    //   return false;
    // }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Prepare the body of the POST request
    const requestBody = {
      uid: uid, // Use uid from URL parameters
      token: token, // Use token from URL parameters
      new_password: password,
      re_new_password: confirmPassword,
    };

    try {
      const response = await fetch('https://9pz3pqmrru.us-east-2.awsapprunner.com/auth/users/reset_password_confirm/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // If the request was successful
        console.log('Password reset successfully');
        setSuccess(true);
      } else {
        // If the server responds with an error status
        const errorData = await response.json();
        setError(errorData.error || 'Failed to reset password. Please try again later.');
      }
    } catch (error) {
      console.error('Password reset failed', error);
      setError('Failed to reset password. Please try again later.');
    }
  };

  return (
    <div className="page-container">
      <div className="ptf-site-wrapper animsition ptf-is--about-us">
        <Helmet>
          <title>LikelySpurious - Password Reset</title>
        </Helmet>
        <div className="ptf-site-wrapper__inner">
          <HeaderDefault />
          <div className="column" id="left-column">
            <h2 className='main-headline'>Password Reset</h2>
            {error && <p className="error">{error}</p>}
            {success ? (
              <p>Your password has been successfully reset. You can now <a href="/login">log in</a> with your new password.</p>
            ) : (
              <form className='login-form' onSubmit={handleSubmit}>
                <label htmlFor="password">New Password:</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <button className='form-button' type="submit">Reset Password</button>
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PasswordResetPage;
